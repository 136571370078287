import { Component } from "react";

interface OutbrainWidgetProps {
  dataSrc: string;
  dataWidgetId: string;
}

declare global {
  interface Window {
    OBR: {
      extern: {
        renderSpaWidgets: (options: any) => void;
      };
    };
  }
}

export class OutbrainWidget extends Component<OutbrainWidgetProps, Record<string, unknown>> {
  mounted = false;
  refreshWidget() {
    if (this.mounted && typeof window !== "undefined" && window.OBR?.extern?.renderSpaWidgets) {
      window.OBR.extern.renderSpaWidgets(this.props.dataSrc);
    }
  }

  componentDidMount() {
    this.mounted = true;
    this.refreshWidget();
  }

  componentDidUpdate() {
    this.refreshWidget();
  }

  render() {
    const { dataSrc, dataWidgetId } = this.props;
    return <div className="OUTBRAIN" data-src={dataSrc} data-widget-id={dataWidgetId} />;
  }
}

export default OutbrainWidget;
